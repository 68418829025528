import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';

import api from '../../../config/configApi';
import { servDeleteUser } from '../../../services/servDeleteUser';

export function EditUserImage(props) {
  const [image, setImage] = useState('');
  const [id] = useState(props.match.params.id);
  const [endImg, setEndImg] = useState('');

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const editUser = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', image);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await api.put(`/user/edit-user-image/${id}`, formData, headers)
      .then((response) => {
        setStatus({
          type: 'redSuccess',
          mensagem: response.data.mensagem,
        });
      }).catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };

  useEffect(() => {
    const getUser = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await api.get(`/user/user/${id}`, headers)
        .then((response) => {
          if (response.data.user) {
            setEndImg(response.data.endImage);
          } else {
            setStatus({
              type: 'redWarning',
              mensagem: 'Erro: Usuário não encontrado!',
            });
          }
        }).catch((err) => {
          if (err.response) {
            setStatus({
              type: 'redWarning',
              mensagem: err.response.data.mensagem,
            });
          } else {
            setStatus({
              type: 'redWarning',
              mensagem: 'Erro: Tente mais tarde!',
            });
          }
        });
    };

    getUser();
  }, [id]);

  const deleteUser = async (idUser) => {
    const response = await servDeleteUser(idUser);
    if (response) {
      if (response.type === 'success') {
        setStatus({
          type: 'redSuccess',
          mensagem: response.mensagem,
        });
      } else {
        setStatus({
          type: 'error',
          mensagem: response.mensagem,
        });
      }
    } else {
      setStatus({
        type: 'error',
        mensagem: 'Erro: Tente mais tarde!',
      });
    }
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">

              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <div className="row">

                    <div className="col-xl-4 col-md-6 mb-4">
                      <h6 className="m-0 font-weight-bold text-primary">Editar Imagem</h6>
                    </div>

                    <div className="col-xl-2 col-md-6 mb-4">

                      <Link to="/users"><button type="button" className="btn btn-info btn-block">Listar</button></Link>
                      {' '}

                    </div>
                    <div className="col-xl-2 col-md-6 mb-4">
                      <Link to={`/view-user/${id}`}>
                        {' '}
                        <button type="button" className="btn btn-primary  btn-block">Visualizar</button>
                        {' '}
                      </Link>
                      {' '}

                    </div>
                    <div className="col-xl-2 col-md-6 mb-4">
                      <Link to="#">
                        {' '}
                        <button type="button" className="btn btn-danger  btn-block" onClick={() => deleteUser(id)}>Apagar</button>
                        {' '}
                      </Link>
                    </div>
                    <div className="col-xl-4 col-md-6 mb-4" />

                  </div>

                </div>

                <div className="alert-content-adm">
                  {status.type === 'redWarning'
                    ? (
                      <Redirect to={{
                        pathname: '/users',
                        state: {
                          type: 'error',
                          mensagem: status.mensagem,
                        },
                      }}
                      />
                    ) : ''}
                  {status.type === 'redSuccess' ? (
                    <Redirect to={{
                      pathname: `/view-user/${id}`,
                      state: {
                        type: 'success',
                        mensagem: status.mensagem,
                      },
                    }}
                    />
                  ) : ''}
                  {status.type === 'error' ? <p className="alert-danger">{status.mensagem}</p> : ''}
                </div>

                <div className="card-body">
                  <form onSubmit={editUser} className="form-adm">

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="title-input">Imagem</label>
                        <input type="file" name="image" id="image" className="form-control" placeholder="Senha para acessar o sistema" onChange={(e) => setImage(e.target.files[0])} />
                      </div>

                      <div className="col-sm-12">
                        {image ? <img src={URL.createObjectURL(image)} alt="Imagem do usuário" width="150" height="150" /> : <img src={endImg} alt="Imagem do usuário" width="150" height="150" />}
                      </div>
                    </div>
                    <div className="form-group row">
                      <button type="submit" className="btn btn-success  btn-block">Salvar</button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
