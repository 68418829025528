const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;

export const formatToBRL = (value) => {
  const rawValue = String(value).replace(/\D/g, '');

  const numericValue = Number(rawValue) / 100;
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numericValue);
};

export const parseStringOfItemsToArray = (str) => {
  if (!str) return [];
  return str.split(';');
};

export default specialCharacters;

export const formatBRLToFloat = (amount) => (
  parseFloat(amount?.replace(/[^\d,-]/g, '').replace(',', '.') || 0)
);
