import React, { useEffect, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';

import { servDeleteUser } from '../../../services/servDeleteUser';
import api from '../../../config/configApi';
import 'moment/locale/pt-br';

export function ViewPessoa(props) {
  const moment = require('moment');

  const { state } = useLocation();

  const [data, setData] = useState('');
  const [id] = useState(props.match.params.id);

  const [status, setStatus] = useState({
    type: state ? state.type : '',
    mensagem: state ? state.mensagem : '',
  });

  useEffect(() => {
    const getUser = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await api.get(`/pessoa/pessoa/${id}`, headers)
        .then((response) => {
          if (response.data.user) {
            response.data.user.enderecoFormatado = [data.logradouro, data.numero, data.complemento, data.bairro, data.cidade, data.cep].filter((i) => i).join(', ');
            setData(response.data.user);
          } else {
            setStatus({
              type: 'redError',
              mensagem: 'Erro: Pessoa não encontrado!',
            });
          }
        }).catch((err) => {
          if (err.response) {
            setStatus({
              type: 'redError',
              mensagem: err.response.data.mensagem,
            });
          } else {
            setStatus({
              type: 'redError',
              mensagem: 'Erro: Tente mais tarde!',
            });
          }
        });
    };

    getUser();
  }, [id]);

  const deleteUser = async (idUser) => {
    const response = await servDeleteUser(idUser);

    if (response) {
      if (response.type === 'success') {
        setStatus({
          type: 'redSuccess',
          mensagem: response.mensagem,
        });
      } else {
        setStatus({
          type: response.type,
          mensagem: response.mensagem,
        });
      }
    } else {
      setStatus({
        type: 'redError',
        mensagem: 'Erro: Tente mais tarde!',
      });
    }
  };

  const [page, setPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const [unidades, setUnidades] = useState([]);
  const getUnidades = async (page) => {
    if (page === undefined) {
      page = 1;
    }
    setPage(page);
    await api.get(`/unidade/unidades/${page}`)
      .then((response) => {
        setUnidades(response.data);
        console.log(unidades.users);
      }).catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getUnidades();
  }, []);

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">

              <div className="card-header py-4">
                <div className="row">
                  <div className="col-xl-8 col-md-6 mb-4">
                    <h6 className="m-0 font-weight-bold text-primary">
                      {' '}
                      <h1 className="h3 mb-0 text-gray-800">VISUALIZAR CADASTRO DO CLIENTE</h1>
                    </h6>
                  </div>

                  <div className="col-xl-1 col-md-6 mb-4">
                    <Link to="/pessoas">
                      <button type="button" className="btn btn-info btn-block">Listar</button>
                    </Link>
                    {' '}

                  </div>

                  <div className="col-xl-1 col-md-6 mb-4">
                    <Link to={`/edit-pessoa/${data.id}`}>
                      <button type="button" className="btn btn-warning btn-block">Editar</button>
                    </Link>
                    {' '}
                  </div>

                  <div className="col-xl-1 col-md-2 mb-4">

                    <Link to="#">
                      <button type="button" onClick={() => deleteUser(data.id)} className="btn btn-danger btn-block">Apagar</button>
                    </Link>

                  </div>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="h3 mb-0 text-gray-800">Características do Atendimento</h6>
                </div>
                <div className="card-body">
                  <div className="form-group row">

                    <div className="form-group col-md-3">
                      <label htmlFor="inputEmail4">Origem das Ligações</label>
                      <select className="custom-select my-1 mr-sm-2" name="origemdasligacoes" id="origemdasligacoes" placeholder="origemdasligacoes" value={data.origemdasligacoes} disabled>

                        <option value="">Selecione o Origem</option>
                        <option value="0800">0800</option>
                        <option value="WhatsAPP">WhatsAPP</option>
                        <option value="Escritório">Escritório</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputEmail4">Atendente</label>
                      <select className="custom-select my-1 mr-sm-2" name="atendente" id="atendente" placeholder="atendente" value={data.atendente} disabled>
                        <option value="">Atendente</option>
                        <option value="FRANCIELE">FRANCIELE</option>
                      </select>
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="inputPassword4">Data das Ligações</label>
                      <input type="text" name="datadasligacoes" id="datadasligacoes" className="form-control" value={data.datadasligacoes} disabled />
                    </div>

                    <div className="form-group col-md-2">
                      <label htmlFor="inputEmail4">INDICAÇÃO</label>
                      <select className="custom-select my-1 mr-sm-2" name="indicacao" id="indicacao" placeholder="indicacao" value={data.indicacao} disabled>
                        <option value="">Selecione Indicação</option>
                        <option value="TV GRANDE RIO PETROLINA GR TV1">TV GRANDE RIO PETROLINA GR TV1</option>
                      </select>
                    </div>

                    <div className="form-group col-md-2">
                      <label htmlFor="inputEmail4">Benefício</label>
                      <select className="custom-select my-1 mr-sm-2" name="beneficio" id="beneficio" placeholder="beneficio" value={data.beneficio} disabled>
                        <option value="">Selecione Beneficio</option>
                        <option value="LOAS">LOAS</option>
                      </select>
                    </div>

                  </div>
                </div>

              </div>

              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="h3 mb-0 text-gray-800">Dados do Cliente</h6>
                </div>
                <div className="card-body">

                  <div className="form-group row">
                    <div className="form-group col-md-3">
                      <label htmlFor="inputPassword4">Nome</label>
                      <input type="text" name="nome" id="nome" className="form-control" placeholder="Nome completo do usuário" value={data.nome} disabled />
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="inputPassword4">CPF</label>
                      <input type="text" name="cpf" id="cpf" className="form-control" value={data.cpf} disabled />
                    </div>
                    <div className="form-group col-md-2">
                      {' '}
                      <label htmlFor="inputPassword4">Nascimento</label>
                      <input type="text" name="datanascimento" id="datanascimento" className="form-control" value={data.datanascimento} disabled />
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="inputPassword4">E-Mail</label>
                      <input type="text" name="email" id="email" className="form-control" placeholder="email" value={data.email} disabled />
                    </div>

                    <div className="form-group col-md-3">
                      <label htmlFor="inputEmail4">Unidade</label>
                      <select className="custom-select my-1 mr-sm-2" name="unidade" id="unidade" placeholder="Unidade" value={data.unidade} disabled>
                        <option value="">Unidade Selecionada</option>
                        {unidades?.users?.map((item) => (<option key={item.nome} value={item.nome} selected={item.nome === data.unidade}>{item.nome}</option>))}
                      </select>
                    </div>

                  </div>
                  <div className="form-group row">

                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4">Endereço</label>
                      <input type="text" name="cep" id="cep" className="form-control" autoComplete="on" value={data.enderecoFormatado} disabled />
                    </div>

                  </div>

                  <div className="form-group row">

                    <div className="form-group col-md-2">
                      <label htmlFor="inputPassword4">WhatsApp/Celular</label>
                      <input
                        type="text"
                        name="telefone"
                        id="telefone"
                        className="form-control"
                        placeholder=""

                        value={data.telefone}
                        disabled
                      />
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="inputEmail4">Telefone OBS</label>
                      <select className="custom-select my-1 mr-sm-2" name="telefoneobs" id="telefoneobs" placeholder="telefoneobs" value={data.telefoneobs} disabled>
                        <option value="">Telefone Observação</option>
                        <option value="PRÓPRIO">PRÓPRIO</option>
                        <option value="FAMILIAR">FAMILIAR</option>
                      </select>
                    </div>

                    <div className="form-group col-md-2">
                      <label htmlFor="inputPassword4">Telefone 2</label>
                      <input type="text" name="telefone2" id="telefone2" className="form-control" placeholder="" value={data.telefone2} disabled />
                    </div>
                    <div className="form-group col-md-2">
                      <label htmlFor="inputEmail4">Telefone2 OBS</label>
                      <select className="custom-select my-1 mr-sm-2" name="telefone2obs" id="telefone2obs" placeholder="telefone2obs" value={data.telefone2obs} disabled>
                        <option value="">Telefone Observação</option>
                        <option value="FAMILIAR">FAMILIAR</option>
                      </select>
                    </div>

                    <div className="form-group col-md-2">
                      <label htmlFor="inputPassword4">Telefone 3</label>
                      <input type="text" name="telefone3" id="telefone3" className="form-control" placeholder="" value={data.telefone3} disabled />
                    </div>

                    <div className="form-group col-md-2">
                      <label htmlFor="inputEmail4">Telefone3 OBS</label>
                      <select className="custom-select my-1 mr-sm-2" name="telefone3obs" id="telefone3obs" placeholder="telefone3obs" value={data.telefone3obs} disabled>
                        <option value="">Telefone Observação</option>
                        <option value="FAMILIAR">FAMILIAR</option>
                      </select>
                    </div>

                  </div>

                </div>

              </div>

              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="h3 mb-0 text-gray-800">Dados do Atendimento</h6>
                </div>
                <div className="card-body">

                  <div className="form-group row">

                    <div className="form-group col-md-2">
                      <label htmlFor="inputEmail4">Atendido Por</label>
                      <select className="custom-select my-1 mr-sm-2" name="atendidopor" id="atendidopor" placeholder="atendidopor" value={data.atendidopor} disabled>
                        <option value="">Atendido Por</option>
                        <option value="MARIA VITORIA">MARIA VITORIA</option>
                      </select>
                    </div>

                    <div className="form-group col-md-2">
                      <label htmlFor="inputEmail4">CONTRATO</label>
                      <select className="custom-select my-1 mr-sm-2" name="contrato" id="contrato" placeholder="contrato" value={data.contrato} disabled>
                        <option value="">Selecione Contrato</option>
                        <option value="SIM">SIM</option>
                        <option value="NÃO">NÃO</option>
                      </select>
                    </div>

                  </div>
                </div>

              </div>

              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="h3 mb-0 text-gray-800">Dados Olhar</h6>
                </div>
                <div className="card-body">

                  <div className="form-group row">
                    <div className="form-group col-md-2">
                      <label htmlFor="inputEmail4">Reagendamento</label>
                      <select className="custom-select my-1 mr-sm-2" name="reagendamento" id="reagendamento" placeholder="reagendamento" value={data.reagendamento} disabled>
                        <option value="">Selecione Reagendamento</option>
                        <option value="SIM">SIM</option>
                        <option value="NÃO">NÃO</option>
                      </select>
                    </div>

                    <div className="form-group col-md-2">
                      <label htmlFor="inputEmail4">Lançado Por</label>
                      <select className="custom-select my-1 mr-sm-2" name="lancadopor" id="lancadopor" placeholder="lancadopor" value={data.lancadopor} disabled>
                        <option value="">Selecione Reagendamento</option>
                        <option value="ANDRESSA">ANDRESSA</option>
                        <option value="QUENIA">QUENIA</option>
                        <option value="CAMILA COIMBRA">CAMILA COIMBRA</option>
                        <option value="THAIS">THAIS</option>
                      </select>
                    </div>

                    <div className="form-group col-md-2">
                      <label htmlFor="inputEmail4">Alterado Por</label>
                      <select className="custom-select my-1 mr-sm-2" name="alteradopor" id="alteradopor" placeholder="alteradopor" value={data.alteradopor} disabled>
                        <option value="">Selecione Alterado Por</option>
                        <option value="ANDRESSA">ANDRESSA</option>
                        <option value="QUENIA">QUENIA</option>
                        <option value="CAMILA COIMBRA">CAMILA COIMBRA</option>
                        <option value="THAIS">THAIS</option>
                      </select>
                    </div>

                  </div>
                </div>
              </div>

              <div className="form-group row">

                <div className="col-xl-4 col-md-6 mb-4">
                  <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            PRIMEIRO AGENDAMENTO
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-4">
                              {' '}
                              <label htmlFor="inputPassword4">Data</label>
                              <input type="text" name="datadoagendamento" id="datadoagendamento" className="form-control" value={data.datadoagendamento} disabled />
                            </div>
                            <div className="form-group col-md-4">
                              {' '}
                              <label htmlFor="inputPassword4">Hora</label>
                              <input type="text" name="datadoagendamentohora" id="datadoagendamentohora" className="form-control" value={data.datadoagendamentohora} disabled />
                            </div>
                            <div className="form-group col-md-4">
                              <label htmlFor="inputEmail4">Veio</label>
                              <select className="custom-select my-1 mr-sm-2" name="reagendamento" id="reagendamento" placeholder="reagendamento" value={data.reagendamento} disabled>
                                <option value="">SIM/NÂO</option>
                                <option value="SIM">SIM</option>
                                <option value="NÃO">NÃO</option>
                              </select>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4">
                  <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            SEGUNDO AGENDAMENTO
                          </div>
                          <div className="form-group row">
                            <div className="form-group col-md-6">
                              {' '}
                              <label htmlFor="inputPassword4">Data</label>
                              <input type="text" name="datadoreagendamento" id="datadoreagendamento" placeholder="Data" className="form-control" value={data.datadoreagendamento} disabled />
                            </div>
                            <div className="form-group col-md-6">
                              {' '}
                              <label htmlFor="inputPassword4">Hora</label>
                              <input type="text" name="datadoreagendamentohora" id="datadoreagendamentohora" className="form-control" value={data.datadoreagendamentohora} disabled />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                  <div className="card border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            TERCEIRO AGENDAMENTO
                          </div>
                          <div className="form-group row">

                            <div className="form-group col-md-6">
                              {' '}
                              <label htmlFor="inputPassword4">Data</label>
                              <input type="text" name="datadoreagendamento2" id="datadoreagendamento2" className="form-control" value={data.datadoreagendamento2} disabled />
                            </div>
                            <div className="form-group col-md-6">
                              {' '}
                              <label htmlFor="inputPassword4">Hora</label>
                              <input type="text" name="datadoreagendamento2hora" id="datadoreagendamento2hora" className="form-control" value={data.datadoreagendamento2hora} disabled />
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="h3 mb-0 text-gray-800">DADOS GERENCIAIS DO CLIENTE</h6>
                </div>

                <div className="card-body">

                  <div className="form-group row">
                    <div className="form-group col-md-12">
                      {' '}
                      <label htmlFor="inputPassword4">Observações</label>
                      <input type="text" name="informacaodoatendimento" id="informacaodoatendimento" className="form-control" value={data.informacaodoatendimento} disabled />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="form-group col-md-12">
                      {' '}
                      <label htmlFor="inputPassword4">Status Uso Exclusivo</label>
                      <input type="text" name="statusexclusivo" id="statusexclusivo" className="form-control" value={data.statusexclusivo} disabled />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="form-group col-md-12">
                      {' '}
                      <label htmlFor="inputPassword4">Pasta de Documentos do Cliente: Local no Onedrive</label>
                      <input type="text" name="pastadedocumentos" id="pastadedocumentos" className="form-control" value={data.pastadedocumentos} disabled />
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
