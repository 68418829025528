import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { brazillianDate, brazillianTime } from '../../../utils/date';
import EmptyState from '../../Shared/EmptyState';
import RemarcarModal from '../RemarcarModal';
import CancelarModal from '../CancelarModal';
import api from '../../../config/configApi';

export default function TabelaAtendimentosPresenciais({
  atendimentosPresenciais, unidades, showFloatAlertSuccess, searchAgendamentos,
}) {
  const [tiposAtendimento, setTiposAtendimento] = useState([]);

  const getTiposAtendimento = async () => {
    const res = await api.get('/tipos-de-atendimento');

    setTiposAtendimento(res.data?.tiposdeatendimento);
  };

  useEffect(() => {
    getTiposAtendimento();
  }, []);

  const [comDataDeAtendimento, setComDataDeAtendimento] = useState(false);
  useEffect(() => {
    const hasDataDeAtendimento = atendimentosPresenciais.some(
      (atendimento) => atendimento.realizadoem,
    );
    setComDataDeAtendimento(hasDataDeAtendimento);
  }, [atendimentosPresenciais]);

  return (
    <div className="table-responsive">
      <div className="card-body">
        <table className="table table-sm">
          <thead>
            <tr>
              <th hidden>ID</th>
              <th>
                Nome
              </th>
              { comDataDeAtendimento && <th>Data do atendimento</th> }
              <th>Data do Agendamento</th>
              <th>Hora do Agendamento</th>
              <th>Benefício</th>
              <th>Agendado Por</th>
              <th>Tipo de Atendimento</th>
              <th>Unidades</th>

              <th style={{ width: '30%' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {
              atendimentosPresenciais?.map((atendimento) => (
                <tr key={atendimento.id}>
                  <td hidden>{atendimento.id}</td>
                  <td className="align-middle">
                    <a href={`/edit-pessoa/${atendimento.pessoa.id}`} target="_blank" rel="noreferrer">
                      {atendimento.pessoa.nome}
                    </a>
                  </td>
                  { comDataDeAtendimento && (
                    <td className="align-middle" style={{ borderBottom: '5px solid #dee2e6', padding: '10px' }}>
                      { atendimento.realizadoem && `${brazillianDate(atendimento.realizadoem)} ${brazillianTime(atendimento.realizadoem)}`}
                    </td>
                  )}
                  <td className="align-middle">{brazillianDate(atendimento.agendadopara)}</td>
                  <td className="align-middle">{brazillianTime(atendimento.agendadopara)}</td>
                  <td className="align-middle">{atendimento.pessoa?.beneficio}</td>
                  <td className="align-middle">{atendimento.usuarioqueagendou?.nome}</td>
                  <td className="align-middle">{atendimento.tipoatendimento?.nome}</td>
                  <td className="align-middle">{atendimento.unidade?.nome}</td>

                  <td className="align-middle">
                    { !atendimento.realizadoem && (
                      <div className="row">
                        <div className="col-lg-12 col-xl-4">
                          <Button href={`/pessoas/${atendimento.pessoa.id}/atendimento/${atendimento.id}`} variant="primary m-1" type="submit">
                            <span>
                              <FontAwesomeIcon icon={faHeadset} />
                            </span>
                            &nbsp;Atender
                          </Button>
                        </div>
                        <div className="col-lg-12 col-xl-4">
                          <RemarcarModal
                            key={atendimento.id}
                            id={atendimento.id}
                            unidadeNome={atendimento.unidade.nome}
                            unidadeId={atendimento.unidade.id}
                            oldDataAgendamento={brazillianDate(atendimento.agendadopara)}
                            oldDataAgendamentoHora={brazillianTime(atendimento.agendadopara)}
                            unidades={unidades}
                            tiposAtendimento={tiposAtendimento}
                          />
                        </div>
                        <div className="col-lg-12 col-xl-4">
                          <CancelarModal
                            key={atendimento.id}
                            id={atendimento.id}
                            nome={atendimento.pessoa.nome}
                            unidadeNome={atendimento.unidade.nome}
                            unidadeId={atendimento.unidade.id}
                            oldDataAgendamento={brazillianDate(atendimento.agendadopara)}
                            oldDataAgendamentoHora={brazillianTime(atendimento.agendadopara)}
                            unidades={unidades}
                            showFloatAlertSuccess={showFloatAlertSuccess}
                            searchAgendamentos={searchAgendamentos}
                          />
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <EmptyState isActive={!atendimentosPresenciais.length} />
      </div>
    </div>
  );
}

TabelaAtendimentosPresenciais.defaultProps = {
  atendimentosPresenciais: [],
  searchAgendamentos: () => {},
};

TabelaAtendimentosPresenciais.propTypes = {
  atendimentosPresenciais: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      nome: PropTypes.string,
      agendadopara: PropTypes.string,
      cancelado: PropTypes.bool,
      realizadoem: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      usuarioqueagendouId: PropTypes.number,
      usuarioqueatendeuId: PropTypes.number,
      pessoaId: PropTypes.number,
      tipodeatendimentoId: PropTypes.number,
      unidadeId: PropTypes.number,
      usuarioqueagendou: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
      usuarioqueatendeu: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
      pessoa: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
          beneficio: PropTypes.string,
        }),
      ),
      unidade: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
      tipodeatendimento: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
    }),
  ),
  unidades: PropTypes.objectOf({
    id: PropTypes.number.isRequired,
    nome: PropTypes.string.isRequired,
  }).isRequired,
  showFloatAlertSuccess: PropTypes.func.isRequired,
  searchAgendamentos: PropTypes.func,
};
