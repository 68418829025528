import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';

import api from '../../../config/configApi';

export function EditProfile() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const editUser = async (e) => {
    e.preventDefault();

    if (!(await validate())) return;

    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await api
      .put('/profile/edit-profile', { nome, email }, headers)
      .then((response) => {
        localStorage.setItem('name', nome);
        setStatus({
          type: 'redSuccess',
          mensagem: response.data.mensagem,
        });
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };

  useEffect(() => {
    const getUser = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await api
        .get('/profile/view-profile', headers)
        .then((response) => {
          if (response.data.user) {
            setNome(response.data.user.nome);
            setEmail(response.data.user.email);
          } else {
            setStatus({
              type: 'redWarning',
              mensagem: 'Erro: Usuário não encontrado!',
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            setStatus({
              type: 'redWarning',
              mensagem: err.response.data.mensagem,
            });
          } else {
            setStatus({
              type: 'redWarning',
              mensagem: 'Erro: Tente mais tarde!',
            });
          }
        });
    };

    getUser();
  }, []);

  async function validate() {
    const schema = yup.object().shape({
      email: yup
        .string('Erro: Necessário preencher o campo e-mail!')
        .email('Erro: Necessário preencher o campo e-mail!')
        .required('Erro: Necessário preencher o campo e-mail!'),
      nome: yup
        .string('Erro: Necessário preencher o campo nome!')
        .required('Erro: Necessário preencher o campo nome!'),
    });

    try {
      await schema.validate({ nome, email });
      return true;
    } catch (err) {
      setStatus({ type: 'error', mensagem: err.errors });
      return false;
    }
  }

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <div className="row">
                    <div className="col-xl-10 col-md-6 mb-4">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Editar Perfil
                      </h6>
                    </div>

                    <div className="col-xl-2 col-md-6 mb-4">
                      <Link to="/view-profile">
                        <button
                          type="button"
                          className="btn btn-info btn-block"
                        >
                          Perfil
                        </button>
                      </Link>
                    </div>

                    <div className="col-xl-2 col-md-6 mb-4" />
                  </div>

                  <div className="card-body">
                    <div className="alert-content-adm">
                      {status.type === 'redWarning' ? (
                        <Redirect
                          to={{
                            pathname: '/login',
                            state: {
                              type: 'error',
                              mensagem: status.mensagem,
                            },
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {status.type === 'redSuccess' ? (
                        <Redirect
                          to={{
                            pathname: '/view-profile',
                            state: {
                              type: 'success',
                              mensagem: status.mensagem,
                            },
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {status.type === 'error' ? (
                        <p className="alert-danger">{status.mensagem}</p>
                      ) : (
                        ''
                      )}
                    </div>

                    <form onSubmit={editUser}>
                      <div className="form-group row">
                        <div className="col-sm-10">
                          <label className="col-sm-2 col-form-label">
                            Nome
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            placeholder="Nome completo do usuário"
                            value={nome}
                            onChange={(text) => setNome(text.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-10">
                          <label className="col-sm-2 col-form-label">
                            E-mail
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Melhor e-mail do usuário"
                            value={email}
                            onChange={(text) => setEmail(text.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <button
                          type="submit"
                          className="btn btn-success btn-block"
                        >
                          Salvar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
