import React, { useEffect, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';

import { servDeleteUser } from '../../../services/servDeleteUser';
import api from '../../../config/configApi';
import 'moment/locale/pt-br';

export function ViewUser(props) {
  const moment = require('moment');

  const { state } = useLocation();

  const [data, setData] = useState('');
  const [id] = useState(props.match.params.id);

  console.log(id);
  const [endImg, setEndImg] = useState('');

  const [status, setStatus] = useState({
    type: state ? state.type : '',
    mensagem: state ? state.mensagem : '',
  });

  useEffect(() => {
    const getUser = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await api.get(`/user/user/${id}`, headers)
        .then((response) => {
          if (response.data.user) {
            setEndImg(response.data.endImage);
            setData(response.data.user);
          } else {
            setStatus({
              type: 'redError',
              mensagem: 'Erro: Usuário não encontrado!',
            });
          }
        }).catch((err) => {
          if (err.response) {
            setStatus({
              type: 'redError',
              mensagem: err.response.data.mensagem,
            });
          } else {
            setStatus({
              type: 'redError',
              mensagem: 'Erro: Tente mais tarde!',
            });
          }
        });
    };

    getUser();
  }, [id]);

  const deleteUser = async (idUser) => {
    const response = await servDeleteUser(idUser);

    if (response) {
      if (response.type === 'success') {
        setStatus({
          type: 'redSuccess',
          mensagem: response.mensagem,
        });
      } else {
        setStatus({
          type: response.type,
          mensagem: response.mensagem,
        });
      }
    } else {
      setStatus({
        type: 'redError',
        mensagem: 'Erro: Tente mais tarde!',
      });
    }
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">

              <div className="card shadow mb-4">

                <div className="card-header py-3">

                  <div className="row">

                    <div className="col-xl-2 col-md-6 mb-4">
                      <h6 className="m-0 font-weight-bold text-primary">Visualizar Usuário</h6>
                    </div>

                    <div className="col-xl-1 col-md-6 mb-4">
                      <Link to="/users">
                        <button type="button" className="btn btn-info btn-block">Listar</button>
                      </Link>
                      {' '}

                    </div>

                    <div className="col-xl-1 col-md-6 mb-4">
                      <Link to={`/edit-user/${data.id}`}>
                        <button type="button" className="btn btn-warning btn-block">Editar</button>
                      </Link>
                      {' '}
                    </div>

                    <div className="col-xl-2 col-md-6 mb-4">

                      <Link to={`/edit-user-password/${data.id}`}>
                        <button type="button" className="btn btn-warning btn-block">Editar Senha</button>
                      </Link>
                      {' '}

                    </div>
                    <div className="col-xl-2 col-md-6 mb-4">

                      <Link to={`/edit-user-image/${data.id}`}>
                        <button type="button" className="btn btn-warning btn-block">Editar Imagem</button>
                      </Link>
                      {' '}

                    </div>

                  </div>

                </div>

                <div className="card-header py-3">

                  <div className="card-body">

                    <div className="form-row">

                      <div className="form-group col-md-6">
                        <label htmlFor="inputPassword4">Nome</label>
                        <input type="text" name="nome" id="nome" className="form-control" placeholder="Nome completo do usuário" value={data.nome} disabled />
                      </div>

                      <div className="form-group col-md-5">
                        <label htmlFor="inputEmail4">Email</label>
                        <input type="email" name="email" id="email" className="form-control" placeholder="Melhor e-mail do usuário" value={data.email} disabled />
                      </div>

                      <div className="form-group col-md-1">
                        <label htmlFor="inputPassword4">Nascimento</label>
                        <input type="text" name="datanascimento" id="datanascimento" className="form-control" autoComplete="on" value={data.datanascimento} disabled />
                      </div>

                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-1">
                        <label htmlFor="inputEmail4">CEP</label>
                        <input type="text" name="cep" id="cep" className="form-control" autoComplete="on" value={data.cep} disabled hidden />
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="inputPassword4">Logradouro</label>
                        <input type="text" name="logradouro" id="logradouro" className="form-control" autoComplete="on" value={data.logradouro} disabled hidden />
                      </div>
                      <div className="form-group col-md-1">
                        <label htmlFor="inputPassword4">Número</label>
                        <input type="text" name="numero" id="numero" className="form-control" autoComplete="on" value={data.numero} disabled hidden />
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="inputPassword4">Complemento</label>
                        <input type="text" name="complemento" id="complemento" className="form-control" autoComplete="on" value={data.complemento} disabled hidden />
                      </div>

                      <div className="form-group col-md-2">
                        <label htmlFor="inputPassword4">Bairro</label>
                        <input type="text" name="bairro" id="bairro" className="form-control" autoComplete="on" value={data.bairro} disabled hidden />
                      </div>

                      <div className="form-group col-md-3">
                        <label htmlFor="inputPassword4">Cidade</label>
                        <input type="text" name="cidade" id="cidade" className="form-control" autoComplete="on" value={data.cidade} disabled hidden />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-2">
                        <label htmlFor="inputEmail4">Empresa</label>

                        <select className="custom-select my-1 mr-sm-2" name="empresaId" id="empresaId" placeholder="Empresa Usuário" value={data.empresaId} disabled>
                          <option value="">Selecione o Empresa</option>
                          <option value="1">Marcelo Manhaes</option>
                        </select>
                      </div>

                      <div className="form-group col-md-2">
                        <label htmlFor="inputEmail4">Equipe</label>
                        <select className="custom-select my-1 mr-sm-2" name="equipeId" id="equipeId" placeholder="Equipe Usuário" value={data.equipeId} disabled>
                          <option value="">Selecione o Eqyuoe</option>
                          <option value="1">Padrão</option>
                        </select>

                      </div>

                      <div className="form-group col-md-2">
                        <label htmlFor="inputEmail4">Outra</label>
                        <select className="custom-select my-1 mr-sm-2" name="empresaId" id="outraId" placeholder="Outra Usuário" value={data.outraId} disabled>
                          <option value="">Selecione o Outra</option>
                          <option value="1">Outra</option>
                        </select>

                      </div>

                      <div className="form-group col-md-2">
                        <label htmlFor="inputEmail4">Perfil</label>
                        <select className="custom-select my-1 mr-sm-2" name="perfilId" id="perfilId" placeholder="Perfil Usuário" value={data.perfilId} disabled>
                          <option value="">Selecione o Perfil</option>
                          <option value="1">Master</option>

                        </select>

                      </div>

                      <div className="form-group col-md-3">
                        <label htmlFor="inputPassword4">Celular</label>
                        <input type="text" name="celular" id="celular" className="form-control" autoComplete="on" value={data.celular} disabled hidden />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
