import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const baseURL = 'https://marcelomanhaes.herokuapp.com/';
// const baseURL = 'http://localhost:3001/';

let refreshSubscribers = [];
let refreshPromise = null;

const onRefreshed = (newToken) => {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
};

const axiosInstance = axios.create({
  baseURL,
});

const refreshToken = async () => {
  if (!refreshPromise) {
    refreshPromise = axiosInstance
      .post('login/refresh-token', {
        refreshToken: localStorage.getItem('refreshToken'),
      })
      .then((response) => {
        const { token, refreshToken: newRefreshToken } = response.data;
        const decodedToken = jwtDecode(token);
        const departments = decodedToken?.departments;
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', newRefreshToken);
        localStorage.setItem('departments', departments);
        onRefreshed(token);
        return token;
      })
      .catch((error) => {
        console.error('Token refresh failed:', error);
        localStorage.clear();
        window.location.href = '/login';
        throw error;
      })
      .finally(() => {
        refreshPromise = null; // Reset the promise when refresh is done
      });
  }
  return refreshPromise;
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && error.response?.data?.mensagem === 'TokenExpired' && !originalRequest._retry) {
      originalRequest._retry = true;

      // Queue the current request and wait for the token refresh
      return refreshToken().then((newToken) => {
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return axiosInstance(originalRequest); // Use `axiosInstance` to ensure consistency
      });
    }

    if ([401, 403].includes(error.response?.status)) {
      localStorage.clear();
      window.location.href = '/login';
    }

    return Promise.reject(error);
  },
);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
