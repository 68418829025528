import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'moment/locale/pt-br';
import moment from 'moment/moment';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';

import api from '../../../config/configApi';

export function Chamados() {
  const { state } = useLocation();

  const [data, setData] = useState([]);
  const [page, setPage] = useState('');
  const [lastPage, setLastPage] = useState('');

  const [status, setStatus] = useState({
    type: state ? state.type : '',
    mensagem: state ? state.mensagem : '',
  });

  const getUsers = async (page) => {
    if (page === undefined) {
      page = 1;
    }
    setPage(page);
    await api.get(`/chamado/chamados/${page}`)
      .then((response) => {
        setData(response.data.users);
        setLastPage(response.data.lastPage);
      }).catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">

              <div className="form-group row">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSce-Dcupuy_2i6sBXUHWFCLRgFkvyNSoDin9IB-gMlCw6ITfw/viewform?embedded=true" width="100%" height="1024" frameBorder="0" marginHeight="0" marginWidth="0" allowFullScreen="true">Carregando…</iframe>
              </div>

            </div>
          </div>

        </div>
      </div>
      <LogOutModal />

    </>

  );
}
