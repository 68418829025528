import {
  object, string, number, date, array,
} from 'yup';

import { formatBRLToFloat } from '../../../utils/generic';

export default object().shape({
  amountTotal: string().required('O campo é obrigatório!'),
  downPayment: string().nullable(),
  paymentExemption: string().nullable(),
  dueDay: number().required('O campo é obrigatório!'),
  dueDate: date().required(' '),
  instalmentNumber: number()
    .when(['amountTotal', 'downPayment', 'paymentExemption'], {
      is: (amountTotal, downPayment, paymentExemption) => {
        const total = formatBRLToFloat(amountTotal);
        const exemption = parseFloat(formatBRLToFloat(paymentExemption));
        const downPaymentValue = parseFloat(formatBRLToFloat(downPayment));
        return total > downPaymentValue + exemption;
      },
      then: number().notOneOf([0], 'Campo obrigatório porque o valor pago é menor do que o valor a receber'),
      otherwise: number().nullable(),
    }),
  installments: array().of(
    object({
      dueDate: date()
        .required('A data de vencimento é obrigatória')
        .nullable(),
      downPayment: string(),
    }),
  ),
});
