import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, Title,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Spinner, Alert } from 'react-bootstrap';
import api from '../../../../config/configApi';
import chartOptions from '../../../../utils/chart';
import { brazillianDate } from '../../../../utils/date';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

export const chartData = {
  labels: ['Sim', 'Não'],
  datasets: [
    {
      data: [0, 0],
      backgroundColor: [
        'rgb(54, 235, 162, 0.2)',
        'rgba(255, 99, 132, 0.2)',
      ],
      borderColor: [
        'rgba(54, 235, 162, 1)',
        'rgba(255, 99, 132, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

const relatorioInitialState = {
  countAtendimentos: '-',
  countPessoasLoasIdoso: '-',
  countPessoasLoasDeficiente: '-',
  countPessoasComCras: 0,
  countPessoasSemCras: 0,
  countNaoAssinaramContrato: 0,
  countAssinaramContrato: 0,
};

const chartOptionsCras = chartOptions('Pessoas com CRAS');
const chartOptionsContrato = chartOptions('Assinaram o Contrato');
const chartOptionsAgendamento = chartOptions('Cadastros com Atendimento Agendados');
const chartOptionsAtendimentoRealizado = chartOptions('Atendimentos Realizados');

export default function RelatorioFechamento({
  startDate, endDate, unidadeId, unidadeNome,
}) {
  const [loadingRelatorio, setLoadingRelatorio] = useState(false);
  const [alertError, setAlertError] = useState({ isVisible: false });
  const [relatorio, setRelatorio] = useState(relatorioInitialState);
  const [chartDataAssinaramContrato, setChartDataAssinaramContrato] = useState(chartData);
  const [chartDataPessoasComCras, setChartDataPessoasComCras] = useState(chartData);
  const [chartDataPessoasComAgendamento, setChartDataPessoasComAgendamento] = useState(chartData);
  const [chartDataAtendimentos, setChartDataAtendimentos] = useState(chartData);

  useEffect(async () => {
    setLoadingRelatorio(true);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const unidadeQuery = unidadeId ? `&unidadeId=${unidadeId}` : '';
      const { data } = await api.get(`/atendimentos-presenciais/relatorio-fechamento?dataInicio=${new Date(startDate)?.toISOString()?.substring(0, 10)}&dataFim=${new Date(endDate)?.toISOString()?.substring(0, 10)}${unidadeQuery}`, {}, headers);
      setRelatorio(data?.report?.[0]);
      setChartDataPessoasComCras((currentValue) => {
        const newData = { ...currentValue };
        if (newData?.datasets) {
          newData.datasets = [
            {
              ...newData.datasets[0],
              data: [
                data?.report?.[0]?.countPessoasComCras,
                data?.report?.[0]?.countPessoasSemCras,
              ],
            },
          ];
        }
        return newData;
      });
      setChartDataAssinaramContrato((currentValue) => {
        const newData = { ...currentValue };
        if (newData?.datasets) {
          newData.datasets = [
            {
              ...newData.datasets[0],
              data: [
                data?.report?.[0]?.countAssinaramContrato,
                data?.report?.[0]?.countNaoAssinaramContrato,
              ],
            },
          ];
        }
        return newData;
      });
      setChartDataPessoasComAgendamento((currentValue) => {
        const newData = { ...currentValue };
        if (newData?.datasets) {
          newData.datasets = [
            {
              ...newData.datasets[0],
              data: [
                data?.report?.[0]?.countPessoasAtendimentoAgendado,
                data?.report?.[0]?.countPessoasSemAtendimentoAgendado,
              ],
            },
          ];
        }
        return newData;
      });
      setChartDataAtendimentos((currentValue) => {
        const newData = { ...currentValue };
        if (newData?.datasets) {
          newData.datasets = [
            {
              ...newData.datasets[0],
              data: [
                data?.report?.[0]?.countAtendimentos,
                data?.report?.[0]?.countAtendimentosNaoRealizados,
              ],
            },
          ];
        }
        return newData;
      });
    } catch (err) {
      setRelatorio(relatorioInitialState);
      setAlertError({ isVisible: true, message: err.response.data.mensagem || 'Não foi possível carregar o relatório. Por favor, tente novamente ou entre em contato com o suporte.' });
      window.setTimeout(() => { setAlertError({ isVisible: false }); }, 5000);
    }
    setLoadingRelatorio(false);
  }, [startDate, endDate, unidadeId]);

  return (
    <>
      <h3>
        {unidadeNome || 'Todas as Unidades'}
      </h3>
      <p className="mb-4">
        &nbsp;de:&nbsp;
        {brazillianDate(startDate)}
        &nbsp;até:&nbsp;
        {brazillianDate(endDate)}
      </p>
      {
        !loadingRelatorio ? (
          <>
            <div className="row mt-5">
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-warning shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                          Pessoas Cadastradas (Todas as Unidades)
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {relatorio?.countPessoasCadastradas}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-plus fa-2x text-gray-300" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                          Número de Atendimentos
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {relatorio?.countAtendimentos}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-headset fa-2x text-gray-300" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          Pessoas com LOAS Idoso
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">{relatorio?.countPessoasLoasIdoso}</div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-user fa-2x text-gray-300" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-info shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Pessoas com LOAS Deficiente</div>
                        <div className="row no-gutters align-items-center">
                          <div className="col-auto">
                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{relatorio?.countPessoasLoasDeficiente}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-wheelchair fa-2x text-gray-300" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              {
                !unidadeId && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <Pie
                      data={chartDataPessoasComAgendamento}
                      options={chartOptionsAgendamento}
                    />
                    <br />
                    <p className="text-center">
                      Sim:
                      &nbsp;
                      {relatorio.countPessoasAtendimentoAgendado}
                      &nbsp;&nbsp;
                      Não:
                      &nbsp;
                      {relatorio.countPessoasSemAtendimentoAgendado}
                    </p>
                  </div>
                )
              }
              <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <Pie
                  data={chartDataAtendimentos}
                  options={chartOptionsAtendimentoRealizado}
                />
                <br />
                <p className="text-center">
                  Sim:
                  &nbsp;
                  {relatorio.countAtendimentos}
                  &nbsp;&nbsp;
                  Não:
                  &nbsp;
                  {relatorio.countAtendimentosNaoRealizados}
                </p>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <Pie data={chartDataPessoasComCras} options={chartOptionsCras} />
                <br />
                <p className="text-center">
                  Sim:
                  &nbsp;
                  {relatorio.countPessoasComCras}
                  &nbsp;&nbsp;
                  Não:
                  &nbsp;
                  {relatorio.countPessoasSemCras}
                </p>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <Pie data={chartDataAssinaramContrato} options={chartOptionsContrato} />
                <br />
                <p className="text-center">
                  Sim:
                  &nbsp;
                  {relatorio.countAssinaramContrato}
                  &nbsp;&nbsp;
                  Não:
                  &nbsp;
                  {relatorio.countNaoAssinaramContrato}
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner
              as="span"
              animation="border"
              size="xl"
              role="status"
              aria-hidden="true"
            >
              <span className="sr-only">Carregando...</span>
            </Spinner>
          </div>
        )
      }
      { alertError.isVisible
        && (
        <div className="fixed-top mt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6">
                <Alert variant="danger" onClose={() => setAlertError({ isVisible: false })} dismissible>
                  <p className="text-center mt-3">
                    {alertError.message}
                  </p>
                </Alert>
              </div>
            </div>
          </div>
        </div>
        )}
    </>
  );
}

RelatorioFechamento.defaultProps = {
  unidadeId: null,
  unidadeNome: 'Todas as Unidades',
};

RelatorioFechamento.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  unidadeId: PropTypes.string,
  unidadeNome: PropTypes.string,
};
