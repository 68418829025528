import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Formik } from 'formik';
import {
  Form,
} from 'react-bootstrap';
import Select from 'react-select';
import { formatToBRL } from '../../../utils/generic';
import schema from './RecebimentoSchema';

export default function Recebimento({ pessoaId }) {
  const [recebimento] = useState({
    instalmentNumber: 0, installments: [], amountTotal: '0,00', paymentExemption: '0,00', downPayment: '0,00',
  });

  const paymentsTypes = [
    {
      label: 'Dinheiro',
      value: 'dinheiro',
    },
    {
      label: 'Cheque',
      value: 'cheque',
    },
    {
      label: 'Boleto',
      value: 'boleto',
    },
    {
      label: 'Pix',
      value: 'pix',
    },
    {
      label: 'Transferência Bancária',
      value: 'transferencia-bancaria',
    },
  ];
  const formatBRLToFloat = (value) => {
    if (!value) return 0;
    return parseFloat(value.replace(/[^\d,-]/g, '').replace(',', '.') || 0);
  };

  const updateRecebimento = (e) => {
    console.log(e);
  };
  const isParcelasDisabled = (values) => {
    const total = parseFloat(values.amountTotal?.replace(/[^\d,-]/g, '').replace(',', '.') || 0);
    const exemption = parseFloat(values.paymentExemption?.replace(/[^\d,-]/g, '').replace(',', '.') || 0);
    const downPayment = parseFloat(values.downPayment?.replace(/[^\d,-]/g, '').replace(',', '.') || 0);
    const isDisabled = total <= downPayment + exemption;

    if (isDisabled) {
      values.instalmentNumber = 0;
    }
    return isDisabled;
  };

  return (
    <div>
      <div className="alert alert-warning" role="alert">
        Essa funcionalidade ainda está em desenvolvimento.
      </div>
      <h4>
        Recebimento
      </h4>

      <Formik
        validationSchema={schema}
        onSubmit={updateRecebimento}
        initialValues={{
          ...recebimento,
        }}
        validateOnMount
        enableReinitialize
      >
        {({
          handleBlur,
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="card shadow mb-1">
              <div className="card-header">
                <div className="row align-items-start">
                  <Form.Group
                    className="col-xl-2 col-md-6 mb-1"
                    controlId="validationFormikNome"
                  >
                    <Form.Label>Valor Total*</Form.Label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">R$</div>
                      </div>
                      <Form.Control
                        type="text"
                        name="amountTotal"
                        value={values.amountTotal}
                        onChange={(e) => {
                          const formattedValue = formatToBRL(e.target.value);
                          handleChange({
                            target: {
                              name: 'amountTotal',
                              value: formattedValue,
                            },
                          });
                        }}
                        autoComplete="off"
                        isInvalid={!!errors.amountTotal}
                        isValid={touched.amountTotal && !errors.amountTotal}
                        onBlur={handleBlur}
                        autoFocus
                      />
                    </div>
                    <Form.Control.Feedback />
                    {touched.amountTotal && errors.amountTotal ? (
                      <div className="invalid-feedback">{errors.amountTotal}</div>
                    ) : null}
                  </Form.Group>
                  <Form.Group
                    className="col-xl-2 col-md-6 mb-1"
                    controlId="validationFormikNome"
                  >
                    <Form.Label>Desconto</Form.Label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">R$</div>
                      </div>
                      <Form.Control
                        type="text"
                        name="paymentExemption"
                        value={values.paymentExemption}
                        onChange={(e) => {
                          const formattedValue = formatToBRL(e.target.value);
                          handleChange({
                            target: {
                              name: 'paymentExemption',
                              value: formattedValue,
                            },
                          });
                        }}
                        autoComplete="off"
                        isInvalid={!!errors.paymentExemption}
                        isValid={touched.paymentExemption && !errors.paymentExemption}
                        onBlur={handleBlur}
                      />
                    </div>
                    <Form.Control.Feedback onChange={handleChange} />
                  </Form.Group>
                  <Form.Group
                    className="col-xl-2 col-md-6 mb-1"
                    controlId="validationFormikNome"
                  >
                    <Form.Label>Valor de Entrada</Form.Label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">R$</div>
                      </div>
                      <Form.Control
                        type="text"
                        name="downPayment"
                        value={values.downPayment}
                        onChange={(e) => {
                          const formattedValue = formatToBRL(e.target.value);
                          handleChange({
                            target: {
                              name: 'downPayment',
                              value: formattedValue,
                            },
                          });
                        }}
                        autoComplete="off"
                        isInvalid={!!errors.downPayment}
                        isValid={touched.downPayment && !errors.downPayment}
                        onBlur={handleBlur}
                      />
                    </div>
                    <Form.Control.Feedback />
                  </Form.Group>
                  <Form.Group
                    className="col-xl-2 col-md-6 mb-1"
                    controlId="validationFormikSexo"
                  >
                    <Form.Label>Parcelas</Form.Label>
                    <Form.Select
                      name="instalmentNumber"
                      value={values.instalmentNumber || 0}
                      onChange={handleChange}
                      isValid={touched.instalmentNumber && !errors.instalmentNumber}
                      isInvalid={!!errors.instalmentNumber}
                      disabled={isParcelasDisabled(values)}
                    >
                      <option value="0">Selecione uma opção</option>
                      {[...Array(12)].map((_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.instalmentNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="col-xl-2 col-md-6 mb-1"
                    controlId="validationFormikNome"
                  >
                    <Form.Label>Dia de Vencimento*</Form.Label>
                    <Form.Control
                      type="text"
                      name="dueDay"
                      value={values.dueDay || undefined}
                      onChange={handleChange}
                      isInvalid={!!errors.dueDay}
                      isValid={touched.dueDay && !errors.dueDay}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback />
                  </Form.Group>
                </div>

                { !!formatBRLToFloat(values.downPayment) && (
                  <div>
                    <div className="row">
                      Entrada:
                    </div>
                    <div className="row align-items-end">
                      <Form.Group
                        className="col-xl-2 col-md-6 mb-1"
                        controlId="validationFormikNome"
                      >
                        <Form.Label>Data de Vencimento*</Form.Label>
                        <Form.Control
                          type="date"
                          className="form-control-sm"
                          name="dueDate"
                          value={values.dueDate || undefined}
                          onChange={handleChange}
                          isInvalid={!!errors.dueDate}
                          isValid={touched.dueDate && !errors.dueDate}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback />
                        {touched.dueDate && errors.dueDate ? (
                          <div className="invalid-feedback">{errors.dueDate}</div>
                        ) : null}
                      </Form.Group>
                      <Form.Group
                        className="col-xl-3 col-md-6 mb-1 sm-2"
                        controlId="validationFormikNome"
                      >
                        <Form.Label>Valor do Pagamento</Form.Label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text form-control-sm">R$</div>
                          </div>
                          <Form.Control
                            type="text"
                            className="form-control-sm"
                            name="downPayment"
                            value={values.downPayment || undefined}
                            onChange={(e) => {
                              const formattedValue = formatToBRL(e.target.value);
                              handleChange({
                                target: {
                                  name: 'downPayment',
                                  value: formattedValue,
                                },
                              });
                            }}
                            isInvalid={!!errors.downPayment}
                            isValid={touched.downPayment && !errors.downPayment}
                            onBlur={handleBlur}
                          />
                        </div>
                        <Form.Control.Feedback />
                        {touched.downPayment && errors.downPayment ? (
                          <div className="invalid-feedback">{errors.downPayment}</div>
                        ) : null}
                      </Form.Group>
                      <Form.Group
                        className="col-xl-3 col-md-6 mb-1"
                        controlId="validationFormikSexo"
                      >
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                          name="status"
                          className="form-control-sm"
                          value={values.status || ''}
                          onChange={handleChange}
                          isValid={touched.status && !errors.status}
                          isInvalid={!!errors.status}
                          onBlur={handleBlur}
                        >
                          <option>Selecione uma opção</option>
                          <option value="em-aberto">Em Aberto</option>
                          <option value="pago">Pago</option>
                          <option value="em-atraso">Em Atraso</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group
                        className="col-xl-3 col-md-6 mb-1"
                        controlId="validationFormikTipoPagamento"
                      >
                        <Form.Label>Tipo de Pagamento</Form.Label>

                        <Select
                          aria-label="Selecione uma opção"
                          isMulti
                          isClearable
                          name="situacao"
                          value={
                            paymentsTypes?.find((option) => (
                              option.value === values.pagamento
                            ))
                          }
                          onChange={(selectedOptions) => setFieldValue('values.pagamento', selectedOptions)}
                          options={paymentsTypes}
                          placeholder="Pesquisar"
                        />
                      </Form.Group>
                    </div>

                    <div className="row align-items-end">
                      <Form.Group
                        className="col-xl-2 col-md-6 mb-1"
                        controlId="validationFormikDataPagamento"
                      >
                        <Form.Label>Data do Pagamento</Form.Label>
                        <Form.Control
                          type="date"
                          className="form-control-sm"
                          name="paymentDate"
                          value={values.paymentDate || undefined}
                          onChange={handleChange}
                          isInvalid={!!errors.paymentDate}
                          isValid={touched.paymentDate && !errors.paymentDate}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback />
                        {touched.paymentDate && errors.paymentDate ? (
                          <div className="invalid-feedback">{errors.paymentDate}</div>
                        ) : null}
                      </Form.Group>
                      <Form.Group
                        className="col-xl-3 col-md-6 mb-1"
                        controlId="validationFormikNumeroControle"
                      >
                        <Form.Label>Nº de Controle</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control-sm"
                          name="controlNumber"
                          value={values.controlNumber || undefined}
                          onChange={handleChange}
                          isInvalid={!!errors.controlNumber}
                          isValid={touched.controlNumber && !errors.controlNumber}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback />
                      </Form.Group>
                      <Form.Group
                        className="col-xl-3 col-md-6 mb-1"
                        controlId="validationFormikObservacao"
                      >
                        <Form.Label>Observação</Form.Label>
                        <Form.Control
                          as="textarea"
                          className="form-control-sm"
                          rows={1}
                          name="observation"
                          value={values.observation || undefined}
                          onChange={handleChange}
                          isInvalid={!!errors.observation}
                          isValid={touched.observation && !errors.observation}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback />
                      </Form.Group>
                    </div>
                  </div>
                )}

                { [...Array(Number(values.instalmentNumber))]?.map((_, index) => (
                  <div>
                    <div className="row">
                      Parcela
                      {' '}
                      {index + 1}
                      :
                    </div>
                    <div className="row">

                      <Form.Group
                        className="col-xl-2 col-md-6 mb-1 form-control-sm"
                        controlId="validationFormikDueDate"
                      >
                        <Form.Label>Data de Vencimento*</Form.Label>
                        <Form.Control
                          type="date"
                          className="form-control-sm"
                          name={`installments[${index}].dueDate`}
                          value={values.installments?.[index]?.dueDate || undefined}
                          onChange={handleChange}
                          isInvalid={!!errors.installments?.[index]?.dueDate}
                          isValid={
                            touched.installments?.[index]?.dueDate
                            && !errors.installments?.[index]?.dueDate
                          }
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback />
                        {touched.installments?.[index]?.dueDate
                        && errors.installments?.[index]?.dueDate && (
                          <div className="invalid-feedback">{errors.installments[index].dueDate}</div>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="col-xl-3 col-md-6 mb-1"
                        controlId="validationFormikDownPayment"
                      >
                        <Form.Label>Valor do Pagamento</Form.Label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">R$</div>
                          </div>
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name={`installments[${index}].downPayment`}
                            value={values.installments?.[index]?.downPayment || undefined}
                            onChange={(e) => {
                              const formattedValue = formatToBRL(e.target.value);
                              handleChange({
                                target: {
                                  name: `installments[${index}].downPayment`,
                                  value: formattedValue,
                                },
                              });
                            }}
                            isInvalid={!!errors.installments?.[index]?.downPayment}
                            isValid={
                              touched.installments?.[index]?.downPayment
                              && !errors.installments?.[index]?.downPayment
                            }
                            onBlur={handleBlur}
                          />
                        </div>
                        <Form.Control.Feedback />
                        {touched.installments?.[index]?.downPayment
                        && errors.installments?.[index]?.downPayment ? (
                          <div className="invalid-feedback">{errors[`installments[${index}].downPayment`]}</div>
                          ) : null}
                      </Form.Group>
                      <Form.Group
                        className="col-xl-3 col-md-6 mb-1"
                        controlId="validationFormikStatus"
                      >
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                          className="form-control-sm"
                          name={`installments[${index}].status`}
                          value={values.installments?.[index]?.status || ''}
                          onChange={handleChange}
                          isValid={touched.installments?.[index]?.status
                            && !errors.installments?.[index]?.status}
                          isInvalid={!!errors.installments?.[index]?.status}
                          onBlur={handleBlur}
                        >
                          <option>Selecione uma opção</option>
                          <option value="em-aberto">Em Aberto</option>
                          <option value="pago">Pago</option>
                          <option value="em-atraso">Em Atraso</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group
                        className="col-xl-3 col-md-6 mb-1"
                        controlId="validationFormikPaymentType"
                      >
                        <Form.Label>Tipo de Pagamento</Form.Label>
                        <Select
                          aria-label="Selecione uma opção"
                          isMulti
                          isClearable
                          name="situacao"
                          value={
                            paymentsTypes?.find((option) => (
                              option.value === values.installments?.[index]?.paymentType
                            ))
                          }
                          onChange={(selectedOptions) => setFieldValue(`installments[${index}].paymentType`, selectedOptions)}
                          options={paymentsTypes}
                          placeholder="Pesquisar"
                        />
                      </Form.Group>

                      <div className="row">
                        <Form.Group
                          className="col-xl-2 col-md-6 mb-1"
                          controlId="validationFormikPaymentDate"
                        >
                          <Form.Label>Data do Pagamento</Form.Label>
                          <Form.Control
                            className="form-control-sm"
                            type="date"
                            name={`installments[${index}].paymentDate`}
                            value={values.installments?.[index]?.paymentDate || undefined}
                            onChange={handleChange}
                            isInvalid={!!errors.installments?.[index]?.paymentDate}
                            isValid={touched.installments?.[index]?.paymentDate
                              && !errors.installments?.[index]?.paymentDate}
                            onBlur={handleBlur}
                            required
                          />
                          <Form.Control.Feedback />
                          {touched.paymentDate && errors.paymentDate ? (
                            <div className="invalid-feedback">{errors.paymentDate}</div>
                          ) : null}
                        </Form.Group>
                        <Form.Group
                          className="col-xl-3 col-md-6 mb-1 ml-6"
                          controlId="validationFormikControlNumber"
                        >
                          <Form.Label>Nº de Controle</Form.Label>
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name={`installments[${index}].controlNumber`}
                            value={values.installments?.[index]?.controlNumber || undefined}
                            onChange={handleChange}
                            isInvalid={!!errors.installments?.[index]?.controlNumber}
                            isValid={touched.installments?.[index]?.controlNumber
                              && !errors.installments?.[index]?.controlNumber}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback />
                        </Form.Group>
                        <Form.Group
                          className="col-xl-3 col-md-6 mb-1"
                          controlId="validationFormikObservation"
                        >
                          <Form.Label>Observação</Form.Label>
                          <Form.Control
                            as="textarea"
                            className="form-control-sm"
                            rows={1}
                            name={`installments[${index}].observation`}
                            value={values.installments?.[index]?.observation || undefined}
                            onChange={handleChange}
                            isInvalid={!!errors.installments?.[index]?.observation}
                            isValid={touched.installments?.[index]?.observation
                              && !errors.installments?.[index]?.observation}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="row mt-4">
                  <div className="col text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ width: '200px' }}
                      onClick={() => console.log('Salvar clicado')}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

Recebimento.propTypes = {
  pessoaId: PropTypes.string.isRequired,
};
