import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';
import 'moment/locale/pt-br';
import api from '../../../config/configApi';
import getCep from '../../../utils/cep';
import estados from '../../../utils/estados';
import specialCharacters from '../../../utils/generic';
import personStatus from '../../../utils/enums/personStatus';
import paymentStatus from '../../../utils/enums/paymentsStatus';
import { disableButtonAction, enableButtonAction } from '../../../store/isButtonEnabled';

// eslint-disable-next-line import/prefer-default-export
export function AddPessoa() {
  const history = useHistory();

  const [nome, setNome] = useState('');
  const [atendenteId, setAtendenteId] = useState('');
  const [origemdasligacoes, setOrigemDasLigacoes] = useState('');
  const [datadasligacoes, setDataDasLigacoes] = useState('');

  const [erro, setErro] = useState('');

  const handleDataBlur = (e) => {
    const data = e.target.value;
    setDataDasLigacoes(data);

    const dataMin = new Date('2018-01-01');
    const dataMax = new Date('2050-12-31');
    const dataSelecionada = new Date(data);

    if (dataSelecionada < dataMin || dataSelecionada > dataMax) {
      setErro('Data não permitida. Por favor, revise se você preencheu corretamente.');
    } else {
      setErro('');
    }
  };

  const [telefoneprincipal, setTelefoneprincipal] = useState('');
  const [obstelefoneprincipal, setObstelefoneprincipal] = useState('');

  const [telefonesegundo, setTelefonesegundo] = useState('');
  const [obstelefonesegundo, setObstelefonesegundo] = useState('');

  const [telefoneoutros, setTelefoneoutros] = useState('');
  const [obstelefoneoutros, setObstelefoneoutros] = useState('');

  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [datanascimento, setDataNascimento] = useState(null);

  const [beneficio, setBeneficio] = useState('');
  const [indicacao, setIndicacao] = useState('');
  const [observacaoindicacao, setObservacaoindicacao] = useState('');
  const [observacao, setObservacao] = useState('');
  const [estado, setEstado] = useState('');
  const [numero, setNumero] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [cep, setCep] = useState('');
  const [complemento, setComplemento] = useState('');

  const [page, setPage] = useState(1);
  const [beneficios, setBeneficios] = useState([]);
  const [origens, setOrigens] = useState([]);
  const [atendentes, setAtendentes] = useState([]);
  const [indicacoes, setIndicacoes] = useState([]);
  const [lastPage, setLastPage] = useState('');
  const [situacao, setSituacao] = useState('');
  const [situacaoInputText, setSituacaoInputText] = useState('');
  const [pagamento, setPagamento] = useState('');
  const [pagamentoInputText, setPagamentoInputText] = useState('');
  const [unidadeId, setUnidadeId] = useState();
  const [unidades, setUnidades] = useState([]);
  const [unidadesOptions, setUnidadesOptions] = useState(['']);
  const { isButtonEnabled } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [telefoneAlert, setTelefoneAlert] = useState({
    telefoneprincipal: '',
    telefonesegundo: '',
    telefoneoutros: '',
  });

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const validarTelefone = async (e) => {
    const telefone = e.target.value || '';
    try {
      if (!telefone) {
        setTelefoneAlert((prevState) => ({
          ...prevState,
          [e.target.name]: '',
        }));
        return;
      }

      const resposta = await api.post('/pessoa/telefone/check-availability', { telefone }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (resposta.data.result.available) {
        setTelefoneAlert((prevState) => ({
          ...prevState,
          [e.target.name]: '',
        }));
      } else {
        setTelefoneAlert((prevState) => ({
          ...prevState,
          [e.target.name]: 'Aviso: Este telefone já foi utilizado anteriormente',
        }));
      }
    } catch (erro) {
      console.error('Erro ao verificar telefone:', erro);
      setTelefoneAlert((prevState) => ({
        ...prevState,
        [e.target.name]: 'Erro ao validar telefone',
      }));
    }
  };

  const searchCep = async (code) => {
    const cep = await getCep(code);
    if (cep.logradouro) {
      const logradouro = document.getElementById('logradouro');
      logradouro.value = cep.logradouro.toUpperCase();
      setLogradouro(logradouro.value);
    }
    if (cep.localidade) {
      const cidade = document.getElementById('cidade');
      cidade.value = cep.localidade.toUpperCase();
      setCidade(cidade.value);
    }
    if (cep.uf) {
      const estado = document.getElementById('estado');
      estado.value = cep.uf.toUpperCase();
      setEstado(estado.value);
    }
    if (cep.bairro) {
      const bairro = document.getElementById('bairro');
      bairro.value = cep.bairro.toUpperCase();
      setBairro(bairro.value);
    }
  };

  const addUser = async (e) => {
    e.preventDefault();
    dispatch(disableButtonAction());

    if (!(await validate())) return;

    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await api
      .post(
        '/pessoa/pessoa',
        {
          nome,
          atendenteId,
          origemdasligacoes,
          datadasligacoes,
          telefoneprincipal,
          obstelefoneprincipal,
          telefonesegundo,
          obstelefonesegundo,
          telefoneoutros,
          obstelefoneoutros,
          bairro,
          beneficio,
          indicacao,
          observacaoindicacao,
          cidade,
          estado,
          numero,
          logradouro,
          cep,
          complemento,
          observacao,
          situacaoStatus: situacao || null,
          pagamentoStatus: pagamento || null,
          datanascimento,
          unidadeId,
        },
        headers,
      )
      .then((response) => {
        alert('CADASTRO REALIZADO');
        return history.push(`/edit-pessoa/${parseInt(response.data.id)}`);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente novamente!',
          });
        }
      });
    dispatch(enableButtonAction());
  };

  async function validate() {
    const schema = yup.object().shape({
      nome: yup
        .string('Erro: Necessário preencher o campo nome!')
        .required('Erro: Necessário preencher o campo nome!'),
    });

    try {
      await schema.validate({
        nome,
      });
      return true;
    } catch (err) {
      setStatus({
        type: 'error',
        mensagem: err.errors,
      });
      return false;
    }
  }

  const getBeneficios = async () => {
    await api
      .get('/beneficio/beneficios')
      .then((response) => {
        setBeneficios(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };

  const getUnidades = async () => {
    try {
      const { data } = await api.get('/unidade/unidades', {
      });
      setUnidades(data.unidades);
      const options = data.unidades.map((unidade) => (
        <option key={unidade.id} value={unidade.id}>
          {unidade.nome}
        </option>
      ));
      setUnidadesOptions(options);
    } catch (error) {
      setStatus({
        type: 'error',
        mensagem: 'Não foi possível carregar as unidades',
      });
    }
  };

  useEffect(() => {
    getBeneficios();
    getUnidades();
  }, []);

  const getOrigens = async () => {
    await api
      .get('/origem/origens')
      .then((response) => {
        setOrigens(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getOrigens();
  }, []);

  const getAtendentes = async () => {
    await api
      .get('/user/atendentes')
      .then((response) => {
        setAtendentes(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getAtendentes();
  }, []);

  const getIndicacoes = async () => {
    await api
      .get('/indicacao/indicacoes')
      .then((response) => {
        setIndicacoes(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getIndicacoes();
  }, []);

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <form onSubmit={addUser}>
                <div className="row">
                  <div className="col-xl-12 col-md-6 mb-4">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3">
                        <h4 className="h4 mb-0 text-gray-800">ATENDIMENTO AO CLIENTE</h4>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-xl-2 col-md-6 mb-4">
                            <label htmlFor="datadasligacoes">Data Ligação</label>
                            <input
                              type="date"
                              name="datadasligacoes"
                              id="datadasligacoes"
                              className="form-control"
                              placeholder=""
                              onBlur={handleDataBlur}
                              required
                            />
                            {erro && <div className="text-danger mt-2">{erro}</div>}

                          </div>
                          <div className="col-xl-4 col-md-6 mb-4">
                            <label htmlFor="inputEmail4">Origem Ligação</label>
                            <select
                              className="custom-select my-1 mr-sm-2"
                              name="origemdasligacoes"
                              id="origemdasligacoes"
                              placeholder="origemdasligacoes"
                              onChange={(text) => setOrigemDasLigacoes(text.target.value)}
                              required
                            >
                              <option value="">Selecione o Origem</option>
                              {origens?.origens?.map((item) => (
                                <option key={item.id} value={item.nome}>
                                  {item.nome}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-xl-4 col-md-6 mb-4">
                            <label htmlFor="inputEmail4">Atendente</label>
                            <select
                              className="custom-select my-1 mr-sm-2"
                              name="atendenteId"
                              id="atendenteId"
                              placeholder="atendente"
                              onChange={(text) => setAtendenteId(text.target.value)}
                              required
                            >
                              <option value="">Atendente</option>
                              {atendentes?.atendentes?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.nome}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-xl-2 col-md-6 mb-4">
                            <label htmlFor="inputEmail4">Benefício</label>
                            <select
                              className="custom-select my-1 mr-sm-2"
                              name="beneficio"
                              id="beneficio"
                              placeholder="beneficio"
                              onChange={(text) => setBeneficio(text.target.value)}
                              required
                            >
                              <option value="">Selecione Beneficio</option>
                              {beneficios?.beneficios?.map((item) => (
                                <option key={item.id} value={item.nome}>
                                  {item.nome}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-4 col-md-6 mb-4">
                            <label htmlFor="inputEmail4">Indicação</label>
                            <select
                              className="custom-select my-1 mr-sm-2"
                              name="indicacao"
                              id="indicacao"
                              placeholder="indicacao"
                              onChange={(text) => setIndicacao(text.target.value)}
                              required
                            >
                              <option value="">Selecione Indicação</option>
                              {indicacoes?.indicacoes?.map((item) => (
                                <option key={item.id} value={item.nome}>
                                  {item.nome}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-xl-8 col-md-6 mb-4">
                            <label htmlFor="inputPassword4">Observação Indicação</label>
                            <input
                              type="text"
                              name="observacaoindicacao"
                              id="observacaoindicacao"
                              className="form-control"
                              placeholder="Obervação da indicação"
                              onChange={(text) => setObservacaoindicacao(text.target.value.toUpperCase())}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-8 col-md-6 mb-4">
                            <label htmlFor="inputPassword4">Nome*</label>
                            <input
                              type="text"
                              name="nome"
                              id="nome"
                              className="form-control"
                              placeholder="Nome completo"
                              onChange={(text) => setNome(text.target.value.toUpperCase())}
                              required
                            />
                          </div>

                          <Form.Group className="col-lg-2 col-md-6">
                            <Form.Label>Unidades</Form.Label>
                            <Form.Select
                              aria-label="Selecione uma unidade"
                              name="unidade"
                              value={unidadeId}
                              onChange={(e) => setUnidadeId(e.target.value || null)}
                            >
                              <option value="">Selecione uma unidade</option>
                              {unidades.map((unidade) => (
                                <option key={unidade.id} value={unidade.id}>
                                  {unidade.nome}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          <div className="col-xl-2 col-md-6 mb-4">
                            <label htmlFor="inputPassword4">Data Nascimento</label>
                            <input
                              type="date"
                              name="datanascimento"
                              id="datanascimento"
                              className="form-control"
                              autoComplete="on"
                              onChange={(text) => setDataNascimento(text.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <Form.Group className="col-xl-1 col-md-6 mb-4">
                            <Form.Label>CEP</Form.Label>
                            <Form.Control
                              type="text"
                              name="cep"
                              id="cep"
                              onChange={async (e) => {
                                const cepCode = e.target?.value?.replace(specialCharacters, '');
                                if (cepCode.length === 8) await searchCep(cepCode);
                                e.target.value = cepCode;
                                setCep(cepCode);
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="col-xl-2 col-md-6 mb-4">
                            <Form.Label>Logradouro</Form.Label>
                            <Form.Control
                              type="text"
                              name="logradouro"
                              id="logradouro"
                              onChange={(text) => setLogradouro(text.target.value.toUpperCase())}
                            />
                          </Form.Group>
                          <Form.Group className="col-xl-1 col-md-6 mb-4">
                            <Form.Label>Numero</Form.Label>
                            <Form.Control
                              type="text"
                              id="numero"
                              name="numero"
                              onChange={(text) => setNumero(text.target.value)}
                            />
                          </Form.Group>
                          <Form.Group className="col-xl-2 col-md-6 mb-4">
                            <Form.Label>complemento</Form.Label>
                            <Form.Control
                              type="text"
                              name="complemento"
                              id="complemento"
                              onChange={(text) => setComplemento(text.target.value.toUpperCase())}
                            />
                          </Form.Group>

                          <Form.Group className="col-xl-2 col-md-6 mb-4">
                            <Form.Label>Bairro</Form.Label>
                            <Form.Control
                              type="text"
                              name="bairro"
                              id="bairro"
                              onChange={(text) => setBairro(text.target.value.toUpperCase())}
                            />
                          </Form.Group>
                          <Form.Group className="col-xl-2 col-md-6 mb-4">
                            <Form.Label>Cidade</Form.Label>
                            <Form.Control
                              type="text"
                              name="cidade"
                              id="cidade"
                              onChange={(text) => setCidade(text.target.value.toUpperCase())}
                            />
                          </Form.Group>
                          <Form.Group className="col-xl-2 col-md-6 mb-4">
                            <Form.Label>Estado</Form.Label>
                            <Form.Select
                              aria-label="Selecione uma opção"
                              name="estado"
                              id="estado"
                              onChange={(text) => setEstado(text.target.value)}
                            >
                              <option>Selecione uma opção</option>
                              {Object.entries(estados).map((estado) => (
                                <option key={estado[1]} value={estado[1]}>
                                  {estado[0]}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>

                        <div className="row">
                          <Form.Group className="col-xl-2 col-md-6 mb-4">
                            <Form.Label>Telefone Principal*</Form.Label>
                            <Form.Control
                              type="text"
                              name="telefoneprincipal"
                              id="telefoneprincipal"
                              placeholder=""
                              isInvalid={!!telefoneAlert.telefoneprincipal}
                              onBlur={(e) => {
                                validarTelefone(e);
                              }}
                              onChange={(text) => setTelefoneprincipal(text.target.value)}
                              required
                            />
                            {!!telefoneAlert.telefoneprincipal && (
                              <Form.Control.Feedback type="invalid" className="text-warning">
                                {telefoneAlert.telefoneprincipal}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                          <div className="col-xl-2 col-md-6 mb-4">
                            <label htmlFor="inputPassword4">Obs Telefone</label>
                            <input
                              type="text"
                              name="obstelefoneprincipal"
                              id="obstelefoneprincipal"
                              className="form-control"
                              autoComplete="on"
                              onChange={(text) => setObstelefoneprincipal(text.target.value.toUpperCase())}
                            />
                          </div>
                          <Form.Group className="col-xl-2 col-md-6 mb-4">
                            <Form.Label>2º Telefone</Form.Label>
                            <Form.Control
                              type="text"
                              name="telefonesegundo"
                              id="telefonesegundo"
                              className="form-control"
                              placeholder=""
                              isInvalid={!!telefoneAlert.telefonesegundo}
                              onBlur={(e) => {
                                validarTelefone(e);
                              }}
                              onChange={(text) => setTelefonesegundo(text.target.value)}
                            />
                            {!!telefoneAlert.telefonesegundo && (
                              <Form.Control.Feedback type="invalid" className="text-warning">
                                {telefoneAlert.telefonesegundo}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>

                          <div className="col-xl-2 col-md-6 mb-4">
                            <label htmlFor="inputPassword4">Obs 2º Telefone</label>
                            <input
                              type="text"
                              name="obstelefonesegundo"
                              id="obstelefonesegundo"
                              className="form-control"
                              autoComplete="on"
                              onChange={(text) => setObstelefonesegundo(text.target.value.toUpperCase())}
                            />
                          </div>

                          <Form.Group className="col-xl-2 col-md-6 mb-4">
                            <Form.Label>3º Telefone</Form.Label>
                            <Form.Control
                              type="text"
                              name="telefoneoutros"
                              id="telefoneoutros"
                              className="form-control"
                              placeholder=""
                              isInvalid={!!telefoneAlert.telefoneoutros}
                              onBlur={(e) => {
                                validarTelefone(e);
                              }}
                              onChange={(text) => setTelefoneoutros(text.target.value)}
                            />
                            {!!telefoneAlert.telefoneoutros && (
                              <Form.Control.Feedback type="invalid" className="text-warning">
                                {telefoneAlert.telefoneoutros}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>

                          <div className="col-xl-2 col-md-6 mb-4">
                            <label htmlFor="inputPassword4">Obs 3º Telefone</label>
                            <input
                              type="text"
                              name="obstelefoneoutros"
                              id="obstelefoneoutros"
                              className="form-control"
                              autoComplete="on"
                              onChange={(text) => setObstelefoneoutros(text.target.value.toUpperCase())}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <Form.Group
                            className="col-xl-8 col-md-6 mb-4"
                            md="9"
                            controlId="validationFormikObservacao"
                          >
                            <Form.Label>Observação</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={2}
                              type="text"
                              name="observacao"
                              onChange={(text) => setObservacao(text.target.value.toUpperCase())}
                            />
                          </Form.Group>

                          <Form.Group className="col-xl-2 col-md-6 mb-4" controlId="situacao">
                            <Form.Label>Situação</Form.Label>
                            <Form.Select
                              aria-label="Selecione uma opção"
                              name="situacao"
                              value={situacao}
                              onChange={(e) => {
                                setSituacao(e.target.value);
                                setSituacaoInputText(e.target.options[e.target.selectedIndex].text);
                              }}
                            >
                              <option value="">Selecione uma opção</option>
                              <optgroup label="Análise">
                                {Object.entries(personStatus.ANALISE).map(([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ))}
                              </optgroup>
                              <optgroup label="Decisão">
                                {Object.entries(personStatus.DECISAO).map(([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ))}
                              </optgroup>
                              <optgroup label="Interação">
                                {Object.entries(personStatus.INTERACAO).map(([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ))}
                              </optgroup>
                              <optgroup label="Processo">
                                {Object.entries(personStatus.PROCESSO).map(([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ))}
                              </optgroup>
                              <optgroup label="Outros">
                                {Object.entries(personStatus.OUTROS).map(([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ))}
                              </optgroup>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group className="col-xl-2 col-md-6 mb-4" controlId="pagamento">
                            <Form.Label>Pagamento</Form.Label>
                            <Form.Select
                              aria-label="Selecione uma opção"
                              name="pagamento"
                              value={pagamento}
                              onChange={(e) => {
                                setPagamento(e.target.value);
                                setPagamentoInputText(
                                  e.target.options[e.target.selectedIndex].text,
                                );
                              }}
                            >
                              <option value="">Selecione uma opção</option>
                              {Object.entries(paymentStatus).map(([code, name]) => (
                                <option key={code} value={code}>
                                  {name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="row">
                          <div className="col-xl-12 col-md-6 mb-4">
                            <button type="submit" className="btn btn-success btn-block" disabled={!isButtonEnabled}>
                              Salvar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
