import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner, Alert } from 'react-bootstrap';
import api from '../../../../config/configApi';
import EmptyState from '../../../Shared/EmptyState';

export default function LigacoesTable({ startDate, endDate }) {
  const [loadingRelatorio, setLoadingRelatorio] = useState(false);
  const [alertError, setAlertError] = useState({ isVisible: false });
  const [relatorioLigacoes, setRelatorioLigacoes] = useState({ users: [] });

  useEffect(async () => {
    setLoadingRelatorio(true);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const { data } = await api.get(`/user/users/calls?dataInicio=${new Date(startDate)?.toISOString()?.substring(0, 10)}&dataFim=${new Date(endDate)?.toISOString()?.substring(0, 10)}`, {}, headers);
      setRelatorioLigacoes(data);
    } catch (err) {
      setAlertError({ isVisible: true, message: err.response.data.mensagem || 'Não foi possível carregar o relatório. Por favor, tente novamente ou entre em contato com o suporte.' });
      window.setTimeout(() => { setAlertError({ isVisible: false }); }, 5000);
    }
    setLoadingRelatorio(false);
  }, [startDate, endDate]);

  const tabelaRelatorioLigacoes = () => (
    relatorioLigacoes?.users?.length ? (
      <table className="table table-sm">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Ligações</th>
          </tr>
        </thead>
        <tbody>
          {
            relatorioLigacoes.users.map((user) => (
              <tr key={user.id}>
                <td>
                  <a href={`/edit-user/${user.id}`} target="_blank" rel="noreferrer">
                    {user.nome}
                  </a>
                </td>
                <td>{user.personCount}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    ) : (
      null
    )
  );

  return (
    <>
      {
        loadingRelatorio ? (
          <div className="d-flex justify-content-center">
            <Spinner
              as="span"
              animation="border"
              size="xl"
              role="status"
              aria-hidden="true"
            >
              <span className="sr-only">Carregando...</span>
            </Spinner>
          </div>
        ) : (
          <div className="card-body">
            { tabelaRelatorioLigacoes() }
            <EmptyState isActive={!relatorioLigacoes?.users?.length} />
          </div>
        )
      }
      { alertError.isVisible
        && (
        <div className="fixed-top mt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6">
                <Alert variant="danger" onClose={() => setAlertError({ isVisible: false })} dismissible>
                  <p className="text-center mt-3">
                    {alertError.message}
                  </p>
                </Alert>
              </div>
            </div>
          </div>
        </div>
        )}
    </>
  );
}

LigacoesTable.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};
