import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';

import api from '../../../config/configApi';

export function EditProfilePassword() {
  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const editUser = async (e) => {
    e.preventDefault();

    if (!(await validate())) return;

    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await api.put('/profile/edit-profile-password', { password }, headers)
      .then((response) => {
        setStatus({
          type: 'redSuccess',
          mensagem: response.data.mensagem,
        });
      }).catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };

  useEffect(() => {
    const getUser = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await api.get('/profile/view-profile', headers)
        .then((response) => {
          if (response.data.user) {
            // setName(response.data.user.name);
            // setEmail(response.data.user.email);
          } else {
            setStatus({
              type: 'redWarning',
              mensagem: 'Erro: Usuário não encontrado!',
            });
          }
        }).catch((err) => {
          if (err.response) {
            setStatus({
              type: 'redWarning',
              mensagem: err.response.data.mensagem,
            });
          } else {
            setStatus({
              type: 'redWarning',
              mensagem: 'Erro: Tente mais tarde!',
            });
          }
        });
    };

    getUser();
  }, []);

  async function validate() {
    const schema = yup.object().shape({
      password: yup.string('Erro: Necessário preencher o campo senha!')
        .required('Erro: Necessário preencher o campo senha!')
        .min(6, 'Erro: A senha deve ter no mínimo 6 caracteres!'),
    });

    try {
      await schema.validate({ password });
      return true;
    } catch (err) {
      setStatus({ type: 'error', mensagem: err.errors });
      return false;
    }
  }

  return (

    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="card shadow mb-4">

                <div className="card-header py-3">

                  <div className="row">

                    <div className="col-xl-10 col-md-6 mb-4">
                      <h6 className="m-0 font-weight-bold text-primary">Editar a Senha</h6>
                    </div>

                    <div className="col-xl-2 col-md-6 mb-4">
                      <Link to="/view-profile">
                        <button type="button" className="btn btn-info btn-block">Perfil</button>
                      </Link>
                    </div>

                    <div className="col-xl-2 col-md-6 mb-4" />

                  </div>

                </div>
                <div className="card-body">
                  <div className="alert-content-adm">
                    {status.type === 'redWarning'
                      ? (
                        <Redirect to={{
                          pathname: '/login',
                          state: {
                            type: 'error',
                            mensagem: status.mensagem,
                          },
                        }}
                        />
                      ) : ''}
                    {status.type === 'redSuccess' ? (
                      <Redirect to={{
                        pathname: '/view-profile',
                        state: {
                          type: 'success',
                          mensagem: status.mensagem,
                        },
                      }}
                      />
                    ) : ''}
                    {status.type === 'error' ? <p className="alert-danger">{status.mensagem}</p> : ''}
                  </div>

                  <form onSubmit={editUser} className="form-adm">

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-sm-2 col-form-label">Senha</label>
                        <input type="password" name="password" id="password" className="form-control" placeholder="Senha para acessar o sistema" autoComplete="on" onChange={(text) => setPassword(text.target.value)} />
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <button type="submit" className="btn btn-warning btn-block">Salvar</button>
                    </div>

                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
