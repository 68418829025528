import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';

import api from '../../../config/configApi';

export function Ramais() {
  const { state } = useLocation();

  const [data, setData] = useState([]);
  const [page, setPage] = useState('');
  const [lastPage, setLastPage] = useState('');

  const [status, setStatus] = useState({
    type: state ? state.type : '',
    mensagem: state ? state.mensagem : '',
  });

  const getUsers = async (page) => {
    if (page === undefined) {
      page = 1;
    }
    setPage(page);
    await api.get(`/ramal/ramais/${page}`)
      .then((response) => {
        setData(response.data.users);
        setLastPage(response.data.lastPage);
      }).catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">

              <div className="card shadow mb-4">

                <div className="card-header py-3">

                  <div className="row">

                    <div className="col-xl-10 col-md-6 mb-4">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Lista de
                        {data.length}
                        {' '}
                        Ramais
                      </h6>
                    </div>
                  </div>

                  <div className="alert-content-adm">
                    {status.type === 'error' ? <p className="alert-danger">{status.mensagem}</p> : ''}
                    {status.type === 'success' ? <p className="alert-success">{status.mensagem}</p> : ''}
                  </div>
                </div>
                <div className="table-responsive">
                  <div className="card-body">

                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th hidden>ID</th>
                          <th>Nome</th>
                          <th>Sala</th>
                          <th>Ramal</th>
                          <th>Unidade</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.map((user) => (
                          <tr key={user.id}>
                            <td hidden>{user.id}</td>
                            <td>{user.nome.toUpperCase()}</td>
                            <td>{user.sala}</td>
                            <td>{user.ramal}</td>
                            <td>{user.unidade}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <LogOutModal />

    </>

  );
}
