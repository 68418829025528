import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { brazillianDate, brazillianTime } from '../../utils/date';
import RemarcarModal from './RemarcarModal';
import CancelarModal from './CancelarModal';
import api from '../../config/configApi';

export default function HistoricoAgendamentoTimeline({
  atendimentosPresenciais, unidades, updateUser, showFloatAlertSuccess,
}) {
  const [tiposAtendimento, setTiposAtendimento] = useState([]);

  const getTiposAtendimento = async () => {
    const res = await api.get('/tipos-de-atendimento');

    setTiposAtendimento(res.data?.tiposdeatendimento);
  };

  function successLi(atendimentosPresencial) {
    return (
      <li className="primary mb-1">
        <div className="card mb-3" style={{ maxWidth: '40rem' }}>
          <div className="card-header text-white bg-success">
            <p className="mt-3">
              {brazillianDate(atendimentosPresencial.agendadopara)}
              &nbsp;- Atendimento Realizado
            </p>
          </div>
          <div className="card-body">
            <p className="card-text">
              <strong>
                Horário:&nbsp;
              </strong>
              {brazillianTime(atendimentosPresencial.agendadopara)}
              <br />
              <strong>
                Agendado por:&nbsp;
              </strong>
              { atendimentosPresencial.usuarioqueagendou.nome }
              <br />
              <strong>
                Atendido por:&nbsp;
              </strong>
              { atendimentosPresencial.usuarioqueatendeu?.nome }
              <br />
              <strong>
                Data do Atendimento:&nbsp;
              </strong>
              { `${`${brazillianDate(atendimentosPresencial.realizadoem)} ${brazillianTime(atendimentosPresencial.realizadoem)}`}`}
              <br />
              <strong>
                Unidade:&nbsp;
              </strong>
              { atendimentosPresencial.unidade.nome }
              <br />
              <strong>
                Tipo de Atendimento :&nbsp;
              </strong>
              { atendimentosPresencial.tipoatendimento?.nome || 'DESCONHECIDO' }
            </p>
          </div>
        </div>
      </li>
    );
  }

  function dangerLi(atendimentosPresencial) {
    return (
      <li className="danger mb-1">
        <div className="card mb-3" style={{ maxWidth: '40rem' }}>
          <div className="card-header text-white bg-danger">
            <p className="mt-3">
              {brazillianDate(atendimentosPresencial.agendadopara)}
              &nbsp;- Atendimento Cancelado
            </p>
          </div>
          <div className="card-body">
            <p className="card-text">
              <strong>
                Horário:&nbsp;
              </strong>
              {brazillianTime(atendimentosPresencial.agendadopara)}
              <br />
              <strong>
                Agendado por:&nbsp;
              </strong>
              { atendimentosPresencial.usuarioqueagendou.nome }
              <br />
              <strong>
                Unidade:&nbsp;
              </strong>
              { atendimentosPresencial.unidade.nome }
              <br />
              <strong>
                Tipo de Atendimento :&nbsp;
              </strong>
              { atendimentosPresencial.tipoatendimento?.nome || 'DESCONHECIDO' }
            </p>
          </div>
        </div>
      </li>
    );
  }

  function secondaryLi(atendimentosPresencial) {
    // eslint-disable-next-line no-return-assign
    return (
      <li className="secondary mb-1">
        <div className="card mb-3" style={{ maxWidth: '40rem' }}>
          <div className="card-header text-white bg-secondary">
            <p className="mt-3">
              {brazillianDate(atendimentosPresencial.agendadopara)}
              &nbsp;- Atendimento Agendado
            </p>
          </div>
          <div className="card-body">
            <p className="card-text">
              <strong>
                Horário:&nbsp;
              </strong>
              {brazillianTime(atendimentosPresencial.agendadopara)}
              <br />
              <strong>
                Agendado por:&nbsp;
              </strong>
              { atendimentosPresencial.usuarioqueagendou.nome }
              <br />
              <strong>
                Unidade:&nbsp;
              </strong>
              { atendimentosPresencial.unidade.nome }
              <br />
              <strong>
                Tipo de Atendimento :&nbsp;
              </strong>
              { atendimentosPresencial.tipoatendimento?.nome || 'DESCONHECIDO' }
              <br />
              <Button href={`/pessoas/${atendimentosPresencial.pessoaId}/atendimento/${atendimentosPresencial.id}`} variant="primary m-1" type="submit">
                <span>
                  <FontAwesomeIcon icon={faHeadset} />
                </span>
                &nbsp;Atender
              </Button>

              <RemarcarModal
                key={atendimentosPresencial.id}
                id={atendimentosPresencial.id}
                unidadeNome={atendimentosPresencial.unidade.nome}
                unidadeId={atendimentosPresencial.unidade.id}
                oldDataAgendamento={brazillianDate(atendimentosPresencial.agendadopara)}
                oldDataAgendamentoHora={brazillianTime(atendimentosPresencial.agendadopara)}
                unidades={unidades}
                tiposAtendimento={tiposAtendimento}
              />
              <CancelarModal
                key={atendimentosPresencial.id}
                id={atendimentosPresencial.id}
                unidadeNome={atendimentosPresencial.unidade.nome}
                unidadeId={atendimentosPresencial.unidade.id}
                oldDataAgendamento={brazillianDate(atendimentosPresencial.agendadopara)}
                oldDataAgendamentoHora={brazillianTime(atendimentosPresencial.agendadopara)}
                unidades={unidades}
                updateUser={updateUser}
                showFloatAlertSuccess={showFloatAlertSuccess}
              />
            </p>
          </div>
        </div>
      </li>
    );
  }

  useEffect(() => {
    getTiposAtendimento();
  }, []);

  function renderUl(atendimentos) {
    return atendimentos.map((atendimentosPresencial) => {
      if (atendimentosPresencial.cancelado) {
        return dangerLi(atendimentosPresencial);
      }
      if (atendimentosPresencial.realizadoem) {
        return successLi(atendimentosPresencial);
      }
      return secondaryLi(atendimentosPresencial);
    });
  }

  return (
    <ul className="timeline">
      { renderUl(atendimentosPresenciais) }
    </ul>
  );
}

HistoricoAgendamentoTimeline.defaultProps = {
  atendimentosPresenciais: [],
};

HistoricoAgendamentoTimeline.propTypes = {
  atendimentosPresenciais: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      nome: PropTypes.string,
      agendadopara: PropTypes.string,
      cancelado: PropTypes.bool,
      realizadoem: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      usuarioqueagendouId: PropTypes.number,
      usuarioqueatendeuId: PropTypes.number,
      pessoaId: PropTypes.number,
      tipodeatendimentoId: PropTypes.number,
      unidadeId: PropTypes.number,
      usuarioqueagendou: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
      usuarioqueatendeu: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
      unidade: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
      tipodeatendimento: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
    }),
  ),
  unidades: PropTypes.objectOf({
    id: PropTypes.number.isRequired,
    nome: PropTypes.string.isRequired,
  }).isRequired,
  updateUser: PropTypes.func.isRequired,
  showFloatAlertSuccess: PropTypes.func.isRequired,
};
